@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    @apply font-kanit font-light leading-[130%];
  }

  h1,h2,h3,h4,h5,h6 {
    @apply font-russo-one leading-[140%];
  }
}

@layer components {
  .btn {
    @apply flex py-4 px-8 justify-center items-center uppercase leading-none cursor-pointer w-fit whitespace-nowrap;
  }

  .btn--primary {
    @apply bg-xton-gold text-black font-kanit text-base font-normal leading-none;
  }

  .btn--outline {
    @apply border-2 border-white border-solid bg-transparent text-white font-normal leading-none;
  }

  .btn--outline-dark {
    @apply border-2 border-black border-solid bg-transparent text-black font-normal leading-none;
  }
}